import { localizedDispatcherRouteName, currentStoreView } from '@vue-storefront/core/lib/multistore';
import rootStore from '@vue-storefront/core/store'

export const transformProductUrl = (product, urlParams = {}) => {
  const currentRoute = rootStore.getters['url/getCurrentRoute']
  const { configurable_children, sku, slug, parentSku, type_id } = product || {}
  const { storeCode, appendStoreCode } = currentStoreView()
  let sortedChildren = []
  let childSku = urlParams['childSku'] || (product?.configurable_children?.[0]?.sku || product.sku)
  if (!currentRoute?.query?.childSku) {
    sortedChildren = (configurable_children || []).slice().sort((a, b) => a.position - b.position)
    childSku = sortedChildren[0]?.sku
  }
  return {
    name: localizedDispatcherRouteName(`${type_id}-product`, storeCode, appendStoreCode),
    params: {
      slug: slug || '',
      parentSku: parentSku || sku || '',
      childSku: childSku || ''
    }
  }
}

export const transformCategoryUrl = (category) => {
  const { storeCode, appendStoreCode } = currentStoreView()
  return {
    name: localizedDispatcherRouteName('category', storeCode, appendStoreCode),
    params: {
      slug: category.slug
    }
  }
}

export const transformCmsPageUrl = (cmsPage) => {
  return {
    name: 'cms-page',
    params: {
      slug: cmsPage.identifier
    }
  }
}
